import { createInertiaApp } from '@inertiajs/react';
import 'Style/app.css';
import { createRoot } from 'react-dom/client';
import MainLayout from 'Layouts/Main/MainLayout';
import React, { JSXElementConstructor, useEffect } from 'react';
import { FormErrorContextProvider } from 'Support/Contexts/FormErrorContext';
import { toast } from 'react-toastify';
import { CheckCircle } from '@phosphor-icons/react';
import axios from 'axios';
import TextToSpeechProvider from 'Components/Audio/TextToSpeechProvider';

window.addEventListener('unhandledrejection', (e) => {
  if (axios.isCancel(e.reason)) {
    e.preventDefault();
    e.stopPropagation();
    return;
  }
});

createInertiaApp({
  resolve: async (name) => {
    const comps = import.meta.glob<{
      default: JSXElementConstructor<any>
    }>('./Pages/**/*.{tsx,jsx}');

    const page = (await (comps[`./Pages/${name}.tsx`] ?? comps[`./Pages/${name}.jsx`])()).default;
    page.layout = page?.layout ?? MainLayout;
    return page;
  },
  setup({ el, App, props }) {
    return createRoot(el).render(
      <App {...props}>
        {({ key, props, Component }) => {
          useEffect(() => {
            if (Array.isArray(props.flash)) {
              props.flash.forEach(({ id, type, message, ...options }) => {
                if (id) {
                  toast.update(id, {
                    autoClose: 5000,
                    delay: 20, // need to specify a delay here to avoid a bug https://github.com/fkhadra/react-toastify/issues/918
                    isLoading: false,
                    type,
                    icon: <CheckCircle />,
                    render: message,
                    closeOnClick: true,
                    ...options
                  });
                } else {
                  toast(message, { type, ...options });
                }
              });
            }
          }, [JSON.stringify(props.flash)]);


          return (
            <TextToSpeechProvider>
              {Component.layout(
                <FormErrorContextProvider>
                  <Component {...props} key={key} />
                </FormErrorContextProvider>
                , props)}
            </TextToSpeechProvider>
          );
        }}
      </App>
    );
  }
});