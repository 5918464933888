import { AnimatePresence, motion } from 'framer-motion';
import { ChatCircleDots, ChatsCircle, Ghost, SignOut, UserCircleGear, UsersFour } from '@phosphor-icons/react';
import MotionLink from 'Components/MotionLink';
import React, { createContext, forwardRef, useContext, useMemo, useRef, useState } from 'react';
import route from 'ziggy-js';
import useUser from 'Support/Hooks/useUser';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Link } from '@inertiajs/react';
import { ToastContainer } from 'react-toastify';
import useFeatures from 'Support/Hooks/useFeatures';
import { createPortal } from 'react-dom';

const LargeNavItem = forwardRef(({ icon, text, href }, ref) => (
  <MotionLink
    ref={ref}
    href={href}
    variants={{
      initial: {
        opacity: 0,
        y: 20
      },
      animate: {
        opacity: 1,
        y: 0
      }
    }}
    style={{
      transition: 'border-color 0.1s, box-shadow 0.1s'
    }}
    className="flex items-center gap-5 rounded-xl -m-2 p-2 border border-transparent hover:border-blue-500 hover:shadow-lg"
  >
    {icon}
    <span>{text}</span>
  </MotionLink>
));

const UserMenu = () => {
  const user = useUser();
  const [open, setOpen] = useState(false);

  return (
    <>
      <DropdownMenu.Root open={open} onOpenChange={setOpen}>
        <DropdownMenu.Trigger asChild>
          <motion.button
            type="button"
            initial={{
              y: 50,
              opacity: 0
            }}
            animate={{
              y: 0,
              opacity: 1
            }}
            transition={{
              type: 'spring',
              stiffness: 500,
              damping: 30
            }}
            className="flex items-center gap-5 self-start focus:outline-none outline-none group/user"
          >
            <div className="relative">
              <img
                src={user?.avatar}
                alt={user?.name || user?.email}
                className="w-10 border-2 rounded-full group-hover/user:shadow-lg duration-100 group-hover/user:border-blue-500 group-focus/user:border-blue-500 group-focus/user:shadow-lg"
              />
              {user?.is_ghosting &&
                <div
                  className="absolute -bottom-2 -right-2 bg-white rounded-full duration-100 bg-white p-1 border-2 group-hover/user:shadow-lg group-hover/user:border-blue-500 group-focus/user:border-blue-500 group-focus/user:shadow-lg">
                  <Ghost className="h-4 w-4" weight="fill" />
                </div>
              }
            </div>
            <span>{user?.name || user?.email}</span>
          </motion.button>
        </DropdownMenu.Trigger>
        <AnimatePresence>
          {open && (
            <DropdownMenu.Portal key="menu-portal" forceMount>
              <DropdownMenu.Content key="menu-content" asChild align="start" side="top" forceMount>
                <motion.div
                  key="options-menu"
                  initial={{
                    scale: 0.95,
                    opacity: 0,
                    y: 5
                  }}
                  animate={{
                    scale: 1,
                    opacity: 1,
                    y: -5
                  }}
                  exit={{
                    opacity: 0,
                    y: 5
                  }}
                  className="flex origin-bottom-left select-none flex-col gap-2 rounded-xl border bg-white p-1 text-sm shadow-lg"
                >
                  {user?.is_ghosting && (
                    <DropdownMenu.Item
                      key="stop-ghosting"
                      textValue="Stop Ghosting"
                      asChild
                    >
                      <Link
                        href={route('ghost.user.stop')}
                        className="flex items-center gap-2 rounded-lg border border-transparent py-1 pl-1 pr-2 outline-0 duration-100 data-[disabled]:pointer-events-none data-[highlighted]:border-blue-500 data-[highlighted]:shadow"
                      >
                        <Ghost className="h-5 w-5" />
                        <span>Stop Ghosting</span>
                      </Link>
                    </DropdownMenu.Item>
                  )}
                  <DropdownMenu.Item
                    key="logout"
                    textValue="Log Out"
                    asChild
                  >
                    <Link
                      href={route('logout')}
                      className="flex items-center gap-2 rounded-lg border border-transparent py-1 pl-1 pr-2 outline-0 duration-100 data-[disabled]:pointer-events-none data-[highlighted]:border-blue-500 data-[highlighted]:shadow"
                    >
                      <SignOut className="h-5 w-5" />
                      <span>Log Out</span>
                    </Link>
                  </DropdownMenu.Item>
                </motion.div>
              </DropdownMenu.Content>
            </DropdownMenu.Portal>
          )}
        </AnimatePresence>
      </DropdownMenu.Root>
    </>
  );
};

const Navigation = () => {
  const features = useFeatures();

  const navItems = useMemo(() => [
    {
      'name': 'My Conversations',
      'icon': <ChatsCircle className="h-6 w-6" weight="bold" />,
      'href': route('threads.index')
    },
    {
      'name': 'My Personas',
      'icon': <UserCircleGear className="h-6 w-6" weight="bold" />,
      'href': route('assistants.index')
    },
    ...features?.hasFeature('users.management') ? [
      {
        'name': 'Users',
        'icon': <UsersFour className="h-6 w-6" weight="bold" />,
        'href': route('users.index')
      }
    ] : []
  ], [features]);

  return (
    <div className="hidden flex-col xl:flex">
      <motion.div
        initial={{
          scale: 2,
          opacity: 0
        }}
        animate={{
          opacity: 1,
          scale: 1
        }}
        transition={{
          type: 'spring',
          stiffness: 500,
          damping: 30
        }}
        className="flex origin-top-left items-center gap-5"
      >
        <ChatCircleDots className="h-10 w-10" />
        <h2 className="text-lg font-medium">TidyBox Chat</h2>
      </motion.div>
      <motion.div
        initial="initial"
        animate="animate"
        className="mt-10 flex flex-col gap-3 grow"
        transition={{
          delayChildren: 0.2,
          staggerChildren: 0.1
        }}
      >
        {navItems.map((item) => (
          <LargeNavItem
            key={item.href}
            href={item.href}
            icon={item.icon}
            text={item.name}
          />
        ))}
      </motion.div>
      <UserMenu />
    </div>
  );
};

const SideBarPortalContext = createContext(null);
export const SideBarPortal = ({ children }) => {
  const sideBarRef = useContext(SideBarPortalContext);

  return createPortal(children, sideBarRef.current ?? document.body);
};

const ContentArea = ({ children }) => {
  const sideBarRef = useRef(null);

  return (
    <>
      <div className="col-start-2 h-full min-h-0 w-full xl:col-span-3">
        <SideBarPortalContext.Provider value={sideBarRef}>
          {children}
        </SideBarPortalContext.Provider>
      </div>
      <div ref={sideBarRef} className="fixed lg:relative flex flex-col items-center justify-center">
      </div>
    </>
  );
};

const MainLayout = (page) => {
  return (
    <div className="relative h-screen w-screen overflow-hidden">
      <div className="flex h-full justify-center px-3 py-5 xl:grid xl:grid-cols-5 gap-5 xl:p-10">
        <Navigation />
        <ContentArea>
          {page}
        </ContentArea>
        <div aria-hidden={true} className="pointer-events-none absolute inset-0 text-white/0">
          TidyBox Chat
        </div>
      </div>
      <ToastContainer autoClose={5000} pauseOnFocusLoss pauseOnHover theme="colored" position="bottom-right" />
    </div>
  );
};

export default MainLayout;