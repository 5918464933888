import React, { useCallback, useEffect, useRef, useState } from 'react';
import TextToSpeechContext, { GenerateAudio } from 'Support/Contexts/TextToSpeechContext';
import axios from 'axios';
import route from 'ziggy-js';
import { useFeature } from 'Support/Hooks/useFeatures';

const TextToSpeechProvider = ({ children }) => {
  const userHasBeenActive = useRef(navigator.userActivation.hasBeenActive);
  const audioContext = useState(() => new AudioContext())[0];

  const abortController = useState(new AbortController())[0];
  const [{ srcNode, loading }, setAudioState] = useState<{
    audio: HTMLAudioElement | null,
    srcNode: MediaElementAudioSourceNode | null,
    loading: boolean
  }>({ audio: null, srcNode: null, loading: false });
  const canGenerateAudio = useFeature('openai.tts');

  const generateAudio = useCallback<GenerateAudio>((text) => {
    if (!canGenerateAudio) return Promise.reject(new Error('TTS is not enabled'));

    setAudioState((prev) => ({
      ...prev,
      loading: true
    }));
    return axios.post(route('audio.tts'), {
      text
    }, {
      responseType: 'blob',
      signal: abortController.signal
    }).then(({ data }) => {
      const audio = new Audio(URL.createObjectURL(data));
      const srcNode = audioContext.createMediaElementSource(audio);

      srcNode.connect(audioContext.destination);

      setAudioState((prev) => {
        if (prev?.audio) {
          prev?.audio?.pause();
          prev?.audio?.remove();
        }

        return { audio, srcNode, loading: false };
      });

      return Promise.resolve({ audio });
    }).catch((e) => {
      setAudioState((prev) => ({
        ...prev,
        loading: false
      }));
      return Promise.reject(e);
    });
  }, [setAudioState, canGenerateAudio]);

  useEffect(() => {
    if (!audioContext) return;

    let timeout: number | null = null;
    const activityCheck = () => {
      userHasBeenActive.current = navigator.userActivation.hasBeenActive;
      if (userHasBeenActive.current) {
        console.info('AudioContext resumed');
        audioContext.resume();
      } else {
        timeout = setTimeout(activityCheck, 50);
      }
    };

    activityCheck();

    return () => {
      if (timeout !== null) {
        clearTimeout(timeout);
      }
    };
  }, [audioContext]);

  return (
    <TextToSpeechContext.Provider value={{
      generateAudio,
      audioContext,
      srcNode,
      loading
    }}>
      {children}
    </TextToSpeechContext.Provider>
  );
};

export default TextToSpeechProvider;