import { useMemo } from 'react';
import { usePage } from '@inertiajs/react';


export const useFeatures = (key = null) => {
  const page = usePage();

  return useMemo(() => {
    const activeFeatures = { features: {...page?.props?.application?.active_features} };

    if (key) {
      return activeFeatures?.features?.[key] ?? false;
    }


    activeFeatures.has = (key) => {
      return !!activeFeatures?.features?.[key];
    };

    activeFeatures.hasFeature = activeFeatures.has;

    return activeFeatures;
  }, [page?.props?.application?.active_features]);
};

export const useFeature = (key) => useFeatures(key);

export default useFeatures;