import { createContext } from 'react';

export type GenerateAudio = (text: string) => Promise<{ audio: HTMLAudioElement }>

interface ITextToSpeechContext {
  generateAudio: GenerateAudio;
  audioContext: AudioContext | null;
  srcNode: MediaElementAudioSourceNode | null;
  loading: boolean;
}

const TextToSpeechContext = createContext<ITextToSpeechContext>({
  generateAudio: (text: string) => Promise.resolve({
    audio: new Audio(),
  }),
  audioContext: null,
  srcNode: null,
  loading: false,
});

export default TextToSpeechContext;
